package osg.cross.graphic

import androidx.compose.animation.core.FastOutLinearInEasing
import androidx.compose.animation.core.LinearEasing
import androidx.compose.animation.core.RepeatMode
import androidx.compose.animation.core.StartOffset
import androidx.compose.animation.core.animateFloat
import androidx.compose.animation.core.infiniteRepeatable
import androidx.compose.animation.core.rememberInfiniteTransition
import androidx.compose.animation.core.tween
import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.geometry.CornerRadius
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.geometry.center
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

fun Modifier.shimmerRadialAnimation(
    runShimmer: Boolean,
    durationMillis: Int = 1000,
): Modifier {
    if (runShimmer.not()) {
        return this
    } else {
        return composed {
            val shimmerColors = listOf(
                Color.Companion.White.copy(alpha = 1.0f),
                Color.Companion.White.copy(alpha = 0.5f),
                Color.Companion.White.copy(alpha = 0.25f),
                Color.Companion.White.copy(alpha = 0.0f),
            )
            val transition = rememberInfiniteTransition(label = "")

            val translateAnimation = transition.animateFloat(
                initialValue = 0f,
                targetValue = 1f,
                animationSpec = infiniteRepeatable(
                    animation = tween(
                        durationMillis = durationMillis,
                        easing = FastOutLinearInEasing,
                    ),
                    repeatMode = RepeatMode.Reverse,
                ),
                label = "Shimmer loading animation",
            )
            this.drawWithContent {
                drawContent()
                val radius = maxOf(size.height, size.width) / 2
                drawRoundRect(
                    brush = Brush.Companion.radialGradient(
                        colors = shimmerColors,
                        center = size.center,
                        radius = radius * translateAnimation.value + 1
                    ),
                )
            }
        }
    }
}

fun Modifier.shimmerLoadingAnimation(
    runShimmer: Boolean,
    widthOfShadowBrush: Int = 500,
    angleOfAxisY: Float = 270f,
    durationMillis: Int = 1000,
    cornerSize: Dp = 0.dp
): Modifier {
    if (runShimmer.not()) {
        return this
    } else {
        return composed {
            val shimmerColors = listOf(
                Color.Companion.White.copy(alpha = 0.3f),
                Color.Companion.White.copy(alpha = 0.5f),
                Color.Companion.White.copy(alpha = 1.0f),
                Color.Companion.White.copy(alpha = 0.5f),
                Color.Companion.White.copy(alpha = 0.3f),
            )
            val cornerSizePx = cornerSize.dpToPx()
            val transition = rememberInfiniteTransition(label = "")

            val translateAnimation = transition.animateFloat(
                initialValue = 0f,
                targetValue = (durationMillis + widthOfShadowBrush).toFloat(),
                animationSpec = infiniteRepeatable(
                    animation = tween(
                        durationMillis = durationMillis,
                        easing = LinearEasing,
                    ),
                    repeatMode = RepeatMode.Reverse,
                ),
                label = "Shimmer loading animation",
            )
            this.drawWithContent {
                drawContent()
                // draws a fully black area with a small keyhole at pointerOffset that’ll show part of the UI.
                drawRoundRect(
                    brush = Brush.Companion.linearGradient(
                        colors = shimmerColors,
                        start = Offset(x = translateAnimation.value - widthOfShadowBrush, y = 0.0f),
                        end = Offset(x = translateAnimation.value, y = angleOfAxisY),
                    ),
                    cornerRadius = CornerRadius(cornerSizePx, cornerSizePx),
                )
            }
        }
    }
}

fun Modifier.coloredRippleAnimationModifier(
    start: Boolean,
    colors: List<Color> = PreGradientBackground.LogoBackground.colors,
    expandFactor: Float = 5f,
    durationMillis: Int = 2500,
): Modifier {
    if (start.not()) {
        return this
    } else {
        return this.composed {
            val transition = rememberInfiniteTransition(label = "ripple")
            val translateAnimations = List(colors.size) { index ->
                transition.animateFloat(
                    initialValue = 0f,
                    targetValue = 1f,
                    animationSpec = infiniteRepeatable(
                        animation = tween(
                            durationMillis = durationMillis,
                            easing = LinearEasing,
                        ),
                        repeatMode = RepeatMode.Restart,
                        initialStartOffset = StartOffset(index * (durationMillis / colors.size))
                    )
                )
            }

            this.drawBehind {
                val radius = (maxOf(size.height, size.width) / 2)*expandFactor
                translateAnimations.forEachIndexed { index, animatable ->
                    drawCircle(
                        color = colors[index].copy(alpha = (1 - animatable.value)),
                        radius = radius * animatable.value,
                        center = size.center,
                    )
                }
            }
        }
    }
}