package osg.cross.theme

import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.ReadOnlyComposable
import androidx.compose.runtime.compositionLocalOf
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp

val LocalDim = compositionLocalOf { Dimensions() }

@Immutable
data class Dimensions(
    val actionIconTopBarSize: Dp = 36.dp,
    val actionIconTopBarSpace: Dp = 24.dp,
    val totalIconTopBarSpace: Dp = 60.dp,
    val smallImageSize: Dp = 40.dp,
)

val MaterialTheme.dimensions: Dimensions
    @Composable
    @ReadOnlyComposable
    get() = LocalDim.current