@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.web.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val contact_mail: DrawableResource by 
      lazy { init_contact_mail() }

  public val event: DrawableResource by 
      lazy { init_event() }

  public val policy: DrawableResource by 
      lazy { init_policy() }

  public val promo_cover: DrawableResource by 
      lazy { init_promo_cover() }

  public val room_service: DrawableResource by 
      lazy { init_room_service() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("contact_mail", CommonMainDrawable0.contact_mail)
  map.put("event", CommonMainDrawable0.event)
  map.put("policy", CommonMainDrawable0.policy)
  map.put("promo_cover", CommonMainDrawable0.promo_cover)
  map.put("room_service", CommonMainDrawable0.room_service)
}

public val Res.drawable.contact_mail: DrawableResource
  get() = CommonMainDrawable0.contact_mail

private fun init_contact_mail(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:contact_mail",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/drawable/contact_mail.xml", -1, -1),
    )
)

public val Res.drawable.event: DrawableResource
  get() = CommonMainDrawable0.event

private fun init_event(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:event",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/drawable/event.xml", -1, -1),
    )
)

public val Res.drawable.policy: DrawableResource
  get() = CommonMainDrawable0.policy

private fun init_policy(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:policy",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/drawable/policy.xml", -1, -1),
    )
)

public val Res.drawable.promo_cover: DrawableResource
  get() = CommonMainDrawable0.promo_cover

private fun init_promo_cover(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:promo_cover",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/drawable/promo_cover.jpg", -1, -1),
    )
)

public val Res.drawable.room_service: DrawableResource
  get() = CommonMainDrawable0.room_service

private fun init_room_service(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:room_service",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/drawable/room_service.xml", -1, -1),
    )
)
