package common

import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.*
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.outlined.MoreVert
import androidx.compose.material3.*
import androidx.compose.material3.adaptive.currentWindowAdaptiveInfo
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.BlendMode
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ColorFilter
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalUriHandler
import androidx.compose.ui.unit.dp
import org.jetbrains.compose.resources.painterResource
import osg.cross.adaptiveUi.isCompact
import osg.cross.common.DropDownOption
import osg.cross.common.OptionsButton
import osg.cross.resource.loadResourceVector
import truematch.crossui.generated.resources.Res
import truematch.crossui.generated.resources.logo_text_no_shadow
import truematch.web.generated.resources.event


@OptIn(ExperimentalMaterial3Api::class)
@Composable
fun WebBar(
    modifier: Modifier = Modifier,
    onWaitingListClick: () -> Unit
) {
    val barColors = TopAppBarDefaults.topAppBarColors().copy(
        containerColor = Color.Transparent,
        actionIconContentColor = Color.White,
    )
    CenterAlignedTopAppBar(
        modifier = modifier.padding(top = 8.dp),
        colors = barColors,
        title = {
            Image(
                modifier = Modifier.height(40.dp),
                painter = painterResource(Res.drawable.logo_text_no_shadow),
                contentDescription = "logo",
                contentScale = ContentScale.FillHeight,
                colorFilter = ColorFilter.tint(Color.White.copy(
                    alpha = 0.5f
                ), BlendMode.SrcAtop)
            )
        },
        actions = {
            val uriHandler = LocalUriHandler.current
            val externalLinkActions = ExternalLinks.entries.map {
                DropDownOption(
                    text = it.text,
                    icon = loadResourceVector(it.icon),
                    onClick = { uriHandler.openUri(it.url) }
                )
            } + DropDownOption(
                text = "Join Waitlist",
                icon = loadResourceVector(truematch.web.generated.resources.Res.drawable.event),
                onClick = onWaitingListClick
            )

            val isCompact = currentWindowAdaptiveInfo().windowSizeClass.isCompact()
            if (isCompact.not()) {
                ActionButtons(
                    actions = externalLinkActions
                )
            } else {
                OptionsButton(
                    dropDownOptions = externalLinkActions,
                    imageVector = Icons.Outlined.MoreVert,
                )
            }
        }
    )
}


@Composable
fun ActionButtons(
    actions: List<DropDownOption>
) {
    actions.forEach { action ->
        TextButton(
            modifier = Modifier,
            onClick = action.onClick,
            colors = ButtonDefaults.textButtonColors().copy(
                contentColor = LocalContentColor.current
            )
        ) {
            Text(
                text = action.text,
            )
        }
    }
}