package osg.cross.landing.ds


import androidx.compose.runtime.Immutable
import androidx.compose.ui.geometry.Size
import androidx.compose.ui.graphics.Canvas
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.drawscope.CanvasDrawScope
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.unit.Density
import androidx.compose.ui.unit.LayoutDirection
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.StringResource
import truematch.crossui.generated.resources.Res
import truematch.crossui.generated.resources.ai_slogan
import truematch.crossui.generated.resources.connect_slogan
import truematch.crossui.generated.resources.focus_slogan
import truematch.crossui.generated.resources.games_slogan
import truematch.crossui.generated.resources.slogan_ai
import truematch.crossui.generated.resources.slogan_ai_description
import truematch.crossui.generated.resources.slogan_connect
import truematch.crossui.generated.resources.slogan_connect_description
import truematch.crossui.generated.resources.slogan_focus
import truematch.crossui.generated.resources.slogan_focus_description
import truematch.crossui.generated.resources.slogan_games
import truematch.crossui.generated.resources.slogan_games_description
import truematch.crossui.generated.resources.slogan_tasty_cashback
import truematch.crossui.generated.resources.slogan_tasty_cashback_description
import truematch.crossui.generated.resources.tasty_cashback
import kotlin.math.roundToInt


enum class SloganContent(
    val title: StringResource,
    val description: StringResource,
    val image: DrawableResource,
){
    Connect(
        title = Res.string.slogan_connect,
        description = Res.string.slogan_connect_description,
        image = Res.drawable.connect_slogan,
    ),
    Ai(
        title = Res.string.slogan_ai,
        description = Res.string.slogan_ai_description,
        image = Res.drawable.ai_slogan
    ),
    Games(
        title = Res.string.slogan_games,
        description = Res.string.slogan_games_description,
        image = Res.drawable.games_slogan,
    ),
    TastyCashback(
        title = Res.string.slogan_tasty_cashback,
        description = Res.string.slogan_tasty_cashback_description,
        image = Res.drawable.tasty_cashback
    ),
    Focus(
        title = Res.string.slogan_focus,
        description = Res.string.slogan_focus_description,
        image = Res.drawable.focus_slogan,
    );

}

@Immutable
data class LoadedSlogan(
    val painter: Painter,
    val dominantColor: Color,
    val title: String,
    val description: String
)

fun Painter.asBitmap(
    density: Density,
    layoutDirection:  LayoutDirection
): ImageBitmap {
    val width: Int = this.intrinsicSize.width.roundToInt()
    val height: Int = this.intrinsicSize.height.roundToInt()
    val bitmap = ImageBitmap(width, height)
    val canvas = Canvas(bitmap)
    val floatSize = Size(width.toFloat(), height.toFloat())

    bitmap.prepareToDraw()

    CanvasDrawScope().draw(
        density = density,
        layoutDirection = layoutDirection,
        canvas = canvas,
        size = floatSize
    ) {
        with(this) {
            draw(floatSize)
        }
    }

    return bitmap
}