@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.crossui.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainDrawable0 {
  public val ai_slogan: DrawableResource by 
      lazy { init_ai_slogan() }

  public val connect_slogan: DrawableResource by 
      lazy { init_connect_slogan() }

  public val focus_slogan: DrawableResource by 
      lazy { init_focus_slogan() }

  public val full_logo: DrawableResource by 
      lazy { init_full_logo() }

  public val games_slogan: DrawableResource by 
      lazy { init_games_slogan() }

  public val logo_text_no_shadow: DrawableResource by 
      lazy { init_logo_text_no_shadow() }

  public val tasty_cashback: DrawableResource by 
      lazy { init_tasty_cashback() }
}

@InternalResourceApi
internal fun _collectCommonMainDrawable0Resources(map: MutableMap<String, DrawableResource>) {
  map.put("ai_slogan", CommonMainDrawable0.ai_slogan)
  map.put("connect_slogan", CommonMainDrawable0.connect_slogan)
  map.put("focus_slogan", CommonMainDrawable0.focus_slogan)
  map.put("full_logo", CommonMainDrawable0.full_logo)
  map.put("games_slogan", CommonMainDrawable0.games_slogan)
  map.put("logo_text_no_shadow", CommonMainDrawable0.logo_text_no_shadow)
  map.put("tasty_cashback", CommonMainDrawable0.tasty_cashback)
}

public val Res.drawable.ai_slogan: DrawableResource
  get() = CommonMainDrawable0.ai_slogan

private fun init_ai_slogan(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:ai_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/ai_slogan.webp", -1, -1),
    )
)

public val Res.drawable.connect_slogan: DrawableResource
  get() = CommonMainDrawable0.connect_slogan

private fun init_connect_slogan(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:connect_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/connect_slogan.webp", -1, -1),
    )
)

public val Res.drawable.focus_slogan: DrawableResource
  get() = CommonMainDrawable0.focus_slogan

private fun init_focus_slogan(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:focus_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/focus_slogan.webp", -1, -1),
    )
)

public val Res.drawable.full_logo: DrawableResource
  get() = CommonMainDrawable0.full_logo

private fun init_full_logo(): DrawableResource = org.jetbrains.compose.resources.DrawableResource(
  "drawable:full_logo",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/full_logo.png", -1, -1),
    )
)

public val Res.drawable.games_slogan: DrawableResource
  get() = CommonMainDrawable0.games_slogan

private fun init_games_slogan(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:games_slogan",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/games_slogan.webp", -1, -1),
    )
)

public val Res.drawable.logo_text_no_shadow: DrawableResource
  get() = CommonMainDrawable0.logo_text_no_shadow

private fun init_logo_text_no_shadow(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:logo_text_no_shadow",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/logo_text_no_shadow.png", -1, -1),
    )
)

public val Res.drawable.tasty_cashback: DrawableResource
  get() = CommonMainDrawable0.tasty_cashback

private fun init_tasty_cashback(): DrawableResource =
    org.jetbrains.compose.resources.DrawableResource(
  "drawable:tasty_cashback",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.crossui.generated.resources/drawable/tasty_cashback.webp", -1, -1),
    )
)
