package slogan
import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.*
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.contentColorFor
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import common.AppBarState
import common.ILandingPage
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringResource
import osg.cross.adaptiveUi.DualAdaptiveLayout
import osg.cross.landing.ds.SloganContent
import osg.cross.resource.asFormattedText
import osg.cross.theme.spacing

@Immutable
class SloganCardPage(
    private val sloganContent: SloganContent
) : ILandingPage {
    override val appBarState: AppBarState = AppBarState.Light
    val idx: Int = SloganContent.entries.indexOf(sloganContent)
    @Composable
    override fun Content(
        innerPadding: PaddingValues,
        isSettled: Boolean
    ) {
        SloganWebCard(
            modifier = Modifier,
            sloganContent = sloganContent,
            idx = idx,
            innerPadding = innerPadding
        )
    }
}

@OptIn(ExperimentalLayoutApi::class)
@Composable
fun SloganWebCard(
    modifier: Modifier = Modifier,
    innerPadding: PaddingValues,
    sloganContent: SloganContent,
    idx: Int
) {
    val title = stringResource(sloganContent.title)
    val containerColor = if (idx % 2 == 0) {
        MaterialTheme.colorScheme.inverseSurface
    } else {
        MaterialTheme.colorScheme.surface
    }
    DualAdaptiveLayout(
        modifier = modifier.fillMaxSize()
            .background(containerColor)
            .background(
            Brush.verticalGradient(
                colorStops = arrayOf(
                    .0f to Color.Black.copy(alpha = .4f),
                    0.4f to Color.Transparent,
                )
            )
        ).padding(innerPadding),
        firstComposable = {
            Image(
                painter = painterResource(sloganContent.image),
                contentDescription = title,
                contentScale = ContentScale.Crop,
                modifier = Modifier
                    .height(600.dp)
                    .aspectRatio(0.6f)
                    .clip(RoundedCornerShape(32.dp))
            )
        },
        secondComposable = {
            SloganWebText(
                modifier = Modifier.width(400.dp).navigationBarsPadding(),
                title = sloganContent.title.asFormattedText(),
                textColor = contentColorFor(containerColor),
                description = sloganContent.description.asFormattedText()
            )
        },
        spacer = {
            Spacer(modifier = Modifier.size(24.dp))
        }
    )
}

@Composable
fun SloganWebText(
    title: String,
    description: String,
    textColor: Color,
    modifier: Modifier = Modifier
) {
    Column(
        modifier = modifier.padding(
            horizontal = MaterialTheme.spacing.small.dp,
        ),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.spacedBy(MaterialTheme.spacing.small.dp)
    ) {
        Text(
            text = title,
            fontWeight = FontWeight.Bold,
            style = MaterialTheme.typography.displayLarge,
            textAlign = TextAlign.Left,
            color = textColor
        )
        Text(
            text = description,
            style = MaterialTheme.typography.headlineSmall,
            textAlign = TextAlign.Left,
            color = textColor
        )
    }
}
