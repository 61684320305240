package osg.cross.resource

private val SimpleStringFormatRegex = Regex("""%(\d?)\$?[ds]""")

fun String.format(vararg formatArgs: Any): String {
    val matchResults = SimpleStringFormatRegex.findAll(this)
    var newString = this
    matchResults.forEachIndexed { i, matchResult ->
        val res = matchResult.groupValues[1]
        val idx = if( res.isEmpty() ) i else res.toInt() - 1
        val arg = formatArgs[idx].toString()
        newString = newString.replaceFirst(matchResult.value, arg)
    }

    return newString.escapeChars()
}

fun String.escapeChars(): String {
    return this.replace("\\'", "'")
}

fun String?.isValidEmail(): Boolean {
    if (this.isNullOrBlank()) return false
    val emailRegex = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$"
    return emailRegex.toRegex().matches(this)
}