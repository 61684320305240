package osg.cross.theme.typography

import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import org.jetbrains.compose.resources.FontResource
import osg.cross.resource.loadResourceFont
import truematch.crossui.generated.resources.Res
import truematch.crossui.generated.resources.ws_v

val trueMatchTypography: Typography
    @Composable
    get() {
        return LoadTypography(
            variableFont = Res.font.ws_v,
        )
    }


@Composable
fun LoadTypography(variableFont: FontResource): Typography {
    val variableFontFamily = loadResourceFont(variableFont)
    return variableFontFamily.unifiedTypography
}