package osg.cross.resource

import androidx.compose.runtime.Composable
import androidx.compose.runtime.Stable
import androidx.compose.ui.text.AnnotatedString
import androidx.compose.ui.text.SpanStyle
import org.jetbrains.compose.resources.StringResource

@Stable
@Composable
fun UiText.asAnnotatedString(
    basedSpanStyle: SpanStyle = SpanStyle(),
): AnnotatedString {
    val rawString = UnpackToRawString()
    return rawString.toAnnotatedString(basedSpanStyle)
}

@Stable
@Composable
private fun UiText.UnpackToRawString(): String {
    val rawString = when (this) {
        is UiText.StringValue -> {
            str.format(*args)
        }

        is UiText.FromStringResource -> {
            val args = args.map {
                when (it) {
                    is StringResource -> {
                        it.toUiText().UnpackToRawString()
                    }

                    is UiText -> {
                        it.UnpackToRawString()
                    }

                    else -> {
                        it
                    }
                }
            }.toTypedArray()
            loadResourceString(this.resourceId).format(*args)

        }
        is UiText.Empty -> {
            ""
        }
    }

    return rawString
}

@Stable
@Composable
fun UiText.asString() = UnpackToRawString()

@Stable
@Composable
fun StringResource.asFormattedText(vararg args: Any): String {
    val rawString = loadResourceString(this)
    return rawString.format(*args)
}

