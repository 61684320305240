package common

import org.jetbrains.compose.resources.DrawableResource
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.policy
import truematch.web.generated.resources.room_service
import truematch.web.generated.resources.contact_mail

enum class ExternalLinks(
    val text: String,
    val url: String,
    val icon: DrawableResource,
) {
    PrivacyPolicy(
        text = "Privacy Policy",
        url = "./privacy",
        icon = Res.drawable.policy
    ),
    TermsOfService(
        text = "Terms of Service",
        url = "./term",
        icon = Res.drawable.room_service,
    ),
    ContactUs(
        text = "Contact Us",
        url = "mailto:support@truematch.life",
        icon = Res.drawable.contact_mail
    )
}