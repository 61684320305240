@file:OptIn(org.jetbrains.compose.resources.InternalResourceApi::class)

package truematch.web.generated.resources

import kotlin.OptIn
import kotlin.String
import kotlin.collections.MutableMap
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.InternalResourceApi

private object CommonMainFont0 {
  public val NotoColorEmoji: FontResource by 
      lazy { init_NotoColorEmoji() }

  public val crimson_text_bold: FontResource by 
      lazy { init_crimson_text_bold() }

  public val crimson_text_regular: FontResource by 
      lazy { init_crimson_text_regular() }
}

@InternalResourceApi
internal fun _collectCommonMainFont0Resources(map: MutableMap<String, FontResource>) {
  map.put("NotoColorEmoji", CommonMainFont0.NotoColorEmoji)
  map.put("crimson_text_bold", CommonMainFont0.crimson_text_bold)
  map.put("crimson_text_regular", CommonMainFont0.crimson_text_regular)
}

public val Res.font.NotoColorEmoji: FontResource
  get() = CommonMainFont0.NotoColorEmoji

private fun init_NotoColorEmoji(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:NotoColorEmoji",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/font/NotoColorEmoji.ttf", -1, -1),
    )
)

public val Res.font.crimson_text_bold: FontResource
  get() = CommonMainFont0.crimson_text_bold

private fun init_crimson_text_bold(): FontResource = org.jetbrains.compose.resources.FontResource(
  "font:crimson_text_bold",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/font/crimson_text_bold.ttf", -1, -1),
    )
)

public val Res.font.crimson_text_regular: FontResource
  get() = CommonMainFont0.crimson_text_regular

private fun init_crimson_text_regular(): FontResource =
    org.jetbrains.compose.resources.FontResource(
  "font:crimson_text_regular",
    setOf(
      org.jetbrains.compose.resources.ResourceItem(setOf(),
    "composeResources/truematch.web.generated.resources/font/crimson_text_regular.ttf", -1, -1),
    )
)
