package osg.cross.adaptiveUi

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.material3.adaptive.currentWindowAdaptiveInfo
import androidx.compose.runtime.Composable
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier

@Composable
fun DualAdaptiveLayout(
    modifier: Modifier = Modifier,
    firstComposable: @Composable () -> Unit,
    secondComposable: @Composable () -> Unit,
    spacer: @Composable () -> Unit = {}
){
    val isCompact = currentWindowAdaptiveInfo().windowSizeClass.isCompact()
    if (isCompact) {
        Column(
            modifier = modifier,
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            Box(modifier = Modifier.weight(1f, fill = false)) {
                firstComposable()
            }
            spacer()
            Box(modifier = Modifier.weight(1f, fill = false)) {
                secondComposable()
            }
        }
    } else {
        Row(
            modifier = modifier,
            horizontalArrangement = Arrangement.Center,
            verticalAlignment = Alignment.CenterVertically
        ) {
            Box(modifier = Modifier.weight(1f, fill = false)) {
                firstComposable()
            }
            spacer()
            Box(modifier = Modifier.weight(1f, fill = false)) {
                secondComposable()
            }
        }
    }
}