package osg.cross.resource

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.ImageBitmap
import androidx.compose.ui.graphics.painter.Painter
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontFamily
import org.jetbrains.compose.resources.DrawableResource
import org.jetbrains.compose.resources.Font
import org.jetbrains.compose.resources.FontResource
import org.jetbrains.compose.resources.StringArrayResource
import org.jetbrains.compose.resources.StringResource
import org.jetbrains.compose.resources.imageResource
import org.jetbrains.compose.resources.painterResource
import org.jetbrains.compose.resources.stringArrayResource
import org.jetbrains.compose.resources.stringResource
import org.jetbrains.compose.resources.vectorResource

@Composable
actual fun loadImageBitmap(drawableResource: DrawableResource): ImageBitmap {
    return imageResource(drawableResource)
}

@Composable
actual fun loadResourceImage(drawableResource: DrawableResource): Painter {
    return painterResource(drawableResource)
}


@Composable
actual fun loadResourceVector(drawableResource: DrawableResource): ImageVector {
    return vectorResource(drawableResource)
}

@Composable
actual fun loadResourceString(stringResource: StringResource): String {
    return stringResource(stringResource)
}

@Composable
actual fun loadResourceArray(stringResource: StringArrayResource): List<String> {
    return stringArrayResource(stringResource)
}

@Composable
actual fun loadResourceFont(fontResource: FontResource): FontFamily {
    val loadedFont = Font(fontResource)
    return FontFamily(loadedFont)
}

actual suspend fun loadLocalPathText(localPath: String): String {
    TODO()
}
