package osg.cross.graphic

import androidx.compose.ui.Modifier
import androidx.compose.ui.composed
import androidx.compose.ui.draw.drawWithContent
import androidx.compose.ui.graphics.BlurEffect
import androidx.compose.ui.graphics.layer.drawLayer
import androidx.compose.ui.graphics.rememberGraphicsLayer

fun Modifier.blurAll(
    enable: Boolean,
    radiusX: Float = 10f,
    radiusY: Float = radiusX,
): Modifier = this.then(
    if (enable) {
        composed {
            val graphicsLayer = rememberGraphicsLayer()
            this.drawWithContent {
                // call record to capture the content in the graphics layer
                graphicsLayer.record {
                    // draw the contents of the composable into the graphics layer
                    this@drawWithContent.drawContent()
                }
                graphicsLayer.renderEffect = BlurEffect(radiusX, radiusY)
                drawLayer(graphicsLayer)
            }
        }
    } else {
        this
    }
)
