package common

import osg.cross.landing.ds.SloganContent
import pages.ComingSoonPage
import promo.PromoPage
import slogan.SloganCardPage

enum class LandingPages(
    val landingPage: ILandingPage
) {
    Promo(PromoPage),
    Connect(SloganContent.Connect.landingPage),
    Ai(SloganContent.Ai.landingPage),
    Games(SloganContent.Games.landingPage),
    TastyCashback(SloganContent.TastyCashback.landingPage),
    Focus(SloganContent.Focus.landingPage),
    WaitingList(ComingSoonPage),
}

val SloganContent.landingPage get() = SloganCardPage(this)
