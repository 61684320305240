package osg.cross.graphic

import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color

enum class PreGradientBackground(
    val colors: List<Color>
){
    Spark(listOf(
        Color(0xffff9a9e),
        Color(0xfffecfef)
    )),
    Game(listOf(
        Color(0xffcc6b8e),
        Color(0xff3f51b1)
    )),
    SparkMoveGradient(listOf(
        Color(0xff9795f0),
        Color(0xfffbc8d4)
    )),
    Grey(listOf(
        Color(0xFF3A3939),
        Color(0xFF9C9DA2)
    )),
    LogoBackground(listOf(
        Color(0xFFD57D52),
        Color(0xFFAB4A46),
        Color(0xFF6A1E3A),
    )),
    LogoTextColors(listOf(
        Color(0xFFF1D2A4),
        Color(0xFFF7E6B4),
        Color(0xFFFFFBF0),
    )),
}

val rainbowColorsBrush =
    Brush.sweepGradient(
        listOf(
            Color(0xFF9575CD),
            Color(0xFFBA68C8),
            Color(0xFFE57373),
            Color(0xFFFFB74D),
            Color(0xFFFFF176),
            Color(0xFFAED581),
            Color(0xFF4DD0E1),
            Color(0xFF9575CD)
        )
    )