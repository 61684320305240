package promo

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.ui.BiasAlignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.text.style.TextAlign
import common.AppBarState
import common.ILandingPage
import osg.cross.resource.loadResourceImage
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.promo_cover


@Immutable
object PromoPage : ILandingPage {
    override val appBarState: AppBarState = AppBarState.Light

    @Composable
    override fun Content(
        innerPadding: PaddingValues,
        isSettled: Boolean
    ) {
        Box {
            Image(
                painter = loadResourceImage(Res.drawable.promo_cover),
                contentDescription = null,
                modifier = Modifier.fillMaxSize(),
                contentScale = ContentScale.Crop
            )
            Box(
                modifier = Modifier.fillMaxSize().background(
                    Brush.verticalGradient(
                        colorStops = arrayOf(
                            .0f to Color.Black.copy(alpha = .4f),
                            0.4f to Color.Transparent,
                        )
                    )
                ),
            )
            Text(
                text = "Sparks real-life connections",
                modifier = Modifier.align(
                    BiasAlignment(0f, -1f)
                ).padding(innerPadding),
                style = MaterialTheme.typography.displaySmall,
                color = Color.White,
                textAlign = TextAlign.Center
            )
        }
    }
}




