package pages

import androidx.compose.foundation.Image
import androidx.compose.foundation.background
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material3.Button
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.material3.TextFieldDefaults
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.clip
import androidx.compose.ui.geometry.Offset
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.style.TextAlign
import androidx.compose.ui.unit.dp
import common.AppBarState
import common.ILandingPage
import osg.cross.adaptiveUi.DualAdaptiveLayout
import osg.cross.graphic.PreGradientBackground
import osg.cross.graphic.coloredRippleAnimationModifier
import osg.cross.resource.isValidEmail
import osg.cross.resource.loadResourceImage
import truematch.crossui.generated.resources.Res
import truematch.crossui.generated.resources.full_logo

enum class DialogStates {
    Initial,
    Show,
    Seen,
    Sent
}

object ComingSoonPage : ILandingPage {
    override val appBarState: AppBarState = AppBarState.Light

    @Composable
    override fun Content(
        innerPadding: PaddingValues,
        isSettled: Boolean,
    ) {
        ComingSoon(
            innerPadding = innerPadding,
            isSettled = isSettled
        )
    }
}


@Composable
fun ComingSoon(
    innerPadding: PaddingValues,
    isSettled: Boolean = true
) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(
                Brush.linearGradient(
                    colors = PreGradientBackground.LogoBackground.colors,
                    start = Offset(0f, Float.POSITIVE_INFINITY),
                    end = Offset(Float.POSITIVE_INFINITY, 0f)
                )
            ),
    ) {
        Column(
            modifier = Modifier.padding(innerPadding).align(Alignment.Center),
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            Text(
                text = "Coming soon",
                modifier = Modifier,
                style = MaterialTheme.typography.displaySmall,
                color = Color.White,
                textAlign = TextAlign.Center
            )
            Spacer(modifier = Modifier.height(16.dp))
            Image(
                painter = loadResourceImage(Res.drawable.full_logo),
                contentDescription = null,
                modifier = Modifier
                    .size(200.dp),
                contentScale = ContentScale.FillWidth
            )
        }
    }
}

@Composable
fun PopWaitlist(
    onSendMail : (String) -> Unit
) {
    var text by remember { mutableStateOf("") }
    var isEmailValid by remember { mutableStateOf(true) }
    var isSendClicked by remember { mutableStateOf(false) }
    val focusManager = LocalFocusManager.current
    Column(
        modifier = Modifier.clip(
            shape = RoundedCornerShape(16.dp)
        )
        .background(
            color = Color.Black.copy(alpha = 0.3f),
        ).padding(16.dp),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(16.dp)
    ) {
        Text(
            text = "Join our waitlist",
            color = Color.White,
            style = MaterialTheme.typography.headlineMedium,
        )
        Text(
            text = "We are working hard to bring you the best experience possible. Join our waitlist to be the first to know when we launch.",
            color = Color.White.copy(alpha = 0.75f),
            style = MaterialTheme.typography.bodyMedium,
            textAlign = TextAlign.Center,
        )
        DualAdaptiveLayout(
            spacer = {
                Spacer(modifier = Modifier.size(16.dp))
            },
            firstComposable = {
                TextField(
                    value = text,
                    onValueChange = {
                        isEmailValid = true
                        text = it
                    },
                    label = { Text((if (isEmailValid) "Email" else "Email*")) },
                    modifier = Modifier,
                    keyboardOptions = KeyboardOptions.Default.copy(
                        keyboardType = KeyboardType.Email,
                        imeAction = ImeAction.Done
                    ),
                    keyboardActions = KeyboardActions(
                        onDone = {
                            focusManager.clearFocus()
                        }
                    ),
                    shape = RoundedCornerShape(35.dp),
                    singleLine = true,
                    isError = isEmailValid.not(),
                    colors = TextFieldDefaults.colors().copy(
                        focusedIndicatorColor = Color.Transparent,
                        unfocusedIndicatorColor = Color.Transparent,
                        errorIndicatorColor = Color.Transparent,
                    )
                )
            },
            secondComposable = {

                Button(
                    onClick = {
                        isEmailValid = text.isValidEmail()
                        if (isEmailValid) {
                            isSendClicked = true
                            onSendMail(text)
                        }
                    },
                    modifier = Modifier.height(56.dp)
                        .coloredRippleAnimationModifier(
                            start = isSendClicked,
                            expandFactor = 2f,
                        ),
                    colors = ButtonDefaults.buttonColors().copy(
                        containerColor = if (isSendClicked) Color.Transparent else Color.White,
                        contentColor = Color.Black
                    ),
                    enabled = isSendClicked.not(),
                    shape = RoundedCornerShape(35.dp)
                ) {
                    Text("Join waitlist")
                }
            }
        )
    }
}