package common
import androidx.compose.material3.Typography
import androidx.compose.runtime.Composable
import osg.cross.resource.loadResourceFont
import osg.cross.theme.typography.resolveTypography
import truematch.web.generated.resources.Res
import truematch.web.generated.resources.crimson_text_bold
import truematch.web.generated.resources.crimson_text_regular


val trueMatchWebTypography: Typography
    @Composable
    get() {
        val normal = loadResourceFont(Res.font.crimson_text_regular)
        val bold = loadResourceFont(Res.font.crimson_text_bold)
        return resolveTypography(
            normal = normal,
            semiBold = bold,
            bold = bold,
        )
    }