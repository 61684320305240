import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.Modifier
import androidx.compose.ui.window.CanvasBasedWindow
import database.DatabaseClient
import kotlinx.browser.document
import kotlinx.coroutines.DelicateCoroutinesApi
import kotlinx.coroutines.GlobalScope
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.HTMLDivElement


fun main() {
    val divElement = document.getElementById("splash")
    val divHtmlElement = divElement as HTMLDivElement
    onWasmReady {
        @OptIn(ExperimentalComposeUiApi::class)
        CanvasBasedWindow(canvasElementId = "ComposeTarget") {
            val transitionDuration = 3000
            fadeOutElement(divHtmlElement, transitionDuration)

            WebLandingScreen(
                modifier = Modifier,
                onSendMail = { mail ->
                    val mailNode = mapOf(
                        "email" to mail
                    )
                    DatabaseClient.push(
                        path = "waitingList",
                        body = mailNode
                    )
                }
            )
        }
    }
}

//fun loadAnimation(div: HTMLDivElement): AnimationItem {
//    val jsonFileUrl = "animation_logo.json"
//    val animData = obj<AnimData> {
//        container = div
//        renderer = "svg"
//        loop = true
//        autoplay = true
//        path = jsonFileUrl
//    }
//
//    return lottie.loadAnimation(animData)
//}


@OptIn(DelicateCoroutinesApi::class)
fun fadeOutElement(
    element: HTMLDivElement,
    transitionDuration: Int
) {
    GlobalScope.launch {
        delay(1000)
        val fadeOut = transitionDuration / 3
        element.style.transition = "opacity ${fadeOut}ms ease-out"
        element.style.opacity = "0"
        delay(fadeOut.toLong())
        element.style.zIndex = "-1"
    }
}

/**
 * Return empty JS Object
 */
//fun obj(): dynamic = js("{}")

/**
 * Helper function for creating JavaScript objects with given type.
 */
//inline fun <T> obj(init: T.() -> Unit): T {
//    return (obj().unsafeCast<T>()).apply(init)
//}