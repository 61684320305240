package osg.cross.common

import androidx.compose.animation.animateContentSize
import androidx.compose.material3.DropdownMenu
import androidx.compose.material3.DropdownMenuItem
import androidx.compose.material3.Icon
import androidx.compose.material3.IconButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.Immutable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Modifier
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.unit.DpOffset
import androidx.compose.ui.unit.dp

@Composable
fun OptionsButton(
    dropDownOptions: List<DropDownOption>,
    imageVector: ImageVector,
) {
    var clicked by remember { mutableStateOf(false) }
    IconButton(
        modifier = Modifier,
        onClick = { clicked = true }
    ) {
        Icon(
            modifier = Modifier,
            imageVector = imageVector,
            contentDescription = null
        )
        DropDownMenu(dropDownOptions, clicked) {
            clicked = false
        }
    }
}

@Composable
fun DropDownMenu(
    buttonContents: List<DropDownOption>,
    expanded: Boolean,
    onDismissRequest: () -> Unit
) {
    DropdownMenu(
        modifier = Modifier.Companion.animateContentSize(),
        expanded = expanded,
        onDismissRequest = onDismissRequest,
        offset = DpOffset(0.dp, 0.dp)
    ) {
        buttonContents.forEach { buttonContent ->
            DropDownItem(
                text = buttonContent.text,
                image = buttonContent.icon,
                isEnabled = buttonContent.isEnabled,
                onClick = {
                    buttonContent.onClick()
                    onDismissRequest()
                }
            )
        }
    }
}


@Composable
fun DropDownItem(
    text: String,
    image: ImageVector,
    isEnabled: Boolean,
    onClick: () -> Unit
) {
    DropdownMenuItem(
        text = { Text(text) },
        leadingIcon = {
            Icon(image, text)
        },
        enabled = isEnabled,
        onClick = onClick
    )
}


@Immutable
data class DropDownOption(
    val icon: ImageVector,
    val text: String = "",
    val isEnabled: Boolean = true,
    val onClick: () -> Unit,
)